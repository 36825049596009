import React from 'react';
import { Loader } from '../../../components';

const Home = () => {
	return (
		<div className='home-page center' style={{ minHeight: '100vh', minWidth: '100vw' }}>
			<Loader />
		</div>
	)
}

export default Home;