import { Box, Button, IconButton, Paper, TextField, Typography } from '@mui/material'
// import { useTheme } from '@mui/styles';
import React, { useState } from 'react'
import { addInterest } from '../../../api';
import { MdOutlineAddAPhoto } from 'react-icons/md'
import Gallerymodel from '../../../components/GalleryModel/Gallerymodel';
import { toast } from 'react-hot-toast';
import { AiOutlinePlus } from 'react-icons/ai';

export default function Dialog({ setOpen, nameofinterest, setAddNew, addnew }) {
    const [input, setInput] = useState({
        name: "",
        description: ""
    });
    const [gallery, setGallery] = useState(false);
    const [imgurl, setImgUrl] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInput({ ...input, [name]: value });
    };
    console.log(input);

    const handleSubmit = async () => {
        const loading = toast.loading("In progress...")
        try {
            const AddData = { name: input.name, icon: imgurl, description: input.description };
            const { data } = await addInterest(AddData);

            if (data.status !== "success") throw new Error(data.message);
            // const newData = addnew.map((ele) => {
            //     if (ele._id === nameofinterest) {
            //         ele.icons = [...ele.icons, data.result];
            //         return ele;
            //     }
            //     return ele;
            // });
            toast.dismiss(loading);
            toast.success("Add successfully")
            setAddNew([...addnew, AddData]);
            setInput("");
            setOpen(false);

        } catch (error) {
            toast.dismiss(loading);
            toast.error(error.message);
            console.log(error);
        }
    };

    return (
        // <Paper variant='none' sx={{ backgroundColor: "white.blight" }} >

        //     <Box sx={{ bgcolor: "white.main", borderRadius: "10px", mb: 2 }}>
        //         <Grid item container sx={{ p: 2, justifyContent: "space-between" }} columns={{ xs: 8, sm: 4, md: 8 }} >
        //             <Grid item sx={{ color: "secondary.main " }}>
        //                 <Typography variant="h6" gutterBottom sx={{ mt: 1, pl: 1, color: "text.main" }} >
        //                     Blog
        //                 </Typography>
        //             </Grid>
        //             <Grid item sx={{ color: "secondary.main " }}>
        //                 <IconButton color='primary' onClick={(e) => handleClickOpenAddModel()}><AiOutlinePlus /></IconButton>
        //             </Grid>
        //         </Grid>
        //     </Box>
        // </Paper>


        <Box>
            <Box classname="dialog-box" sx={{ position: 'fixed', top: '0', left: '0', right: '0', bottom: '0', backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box classname="dialog-inner" sx={{ position: 'relative', borderRadius: "10px", bgcolor: 'white.main', maxWidth: '600px', width: '100%', py: 2, px: 3, }}>
                    <Typography sx={{ fontSize: "15px" }}>Add New Interest</Typography>
                    <TextField sx={{ width: "100%", mt: 2 }} label="Add New" id="outlined-size-small" value={input.name} name='name' onChange={handleChange} size="small" />
                    <TextField sx={{ width: "100%", mt: 2 }} label="decription" id="outlined-size-small" value={input.description} name='description' onChange={handleChange} size="small" />

                    <Box py={1} sx={{ alignItems: "center", display: "flex", gap: 4 }}>
                        <IconButton variant='contained' onClick={() => setGallery(true)} color='primary' size='large' ><MdOutlineAddAPhoto /></IconButton>
                        {imgurl && <img width="40%" height="150px" src={`${imgurl}`} alt="" />}
                    </Box>
                    <Button sx={{ mt: 2 }} variant='contained' onClick={handleSubmit}>Add</Button>
                    <Button sx={{ mt: 2 }} onClick={() => setOpen(false)}>close</Button>
                </Box>
            </Box>

            {gallery && <Gallerymodel setGallery={setGallery} setImgUrl={setImgUrl} />}

        </Box>
    )
}
