import React from 'react'
import { Breadcrumbs } from '@mui/material';
import { BsFillHouseDoorFill, BsFillPeopleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';

export default function Navmap() {

    return (
        <div style={{paddingBottom:"40px"}}>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black', marginl: "1px" }}
                    color="inherit"
                    to="/"
                >
                    <BsFillHouseDoorFill style={{ marginRight: "2px", paddingBottom: "1px" }} />
                    Home
                </Link>
                <Link
                    
                    style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black' }}
                    color="inherit"
                    to="/users"
                >
                    <BsFillPeopleFill style={{ marginRight: "2px", paddingBottom: "1px" }} />
                    Users
                </Link>
            </Breadcrumbs>
        </div>
    )
}
