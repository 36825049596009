import { Box, Button, Grid, IconButton, Pagination, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import AddBlog from './AddBlog';
import { DeletBlog, getBlog } from '../../../api';
import { toast } from 'react-hot-toast';

import { BiEditAlt, BiTrashAlt } from 'react-icons/bi';
import { Spenner } from '../../../components';

export default function Blog() {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [allBlog, setAllBlog] = useState([]);
    const [editedData, setEditData] = useState({});
    const [edit, setEdit] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const limit = 2

    const handleClickOpenAddModel = () => {
        if (!open) {
            setOpen(true);
        } else {
            setOpen(false);
            setEditData({});
            setEdit(false);
        };
    };

    const AllBlog = async (limit, page) => {
        try {
            setLoading(true)
            const { data } = await getBlog(limit, page);
            if (data.status === "success") {
                console.log(data);
                setTotalPages(data.totalPages);
                setAllBlog(data.data);
                setLoading(false);
            }
        } catch (err) {
            console.log(err);
            toast.error(err.response);
        };
    };

    const hendleDelete = async (ele, ind) => {
        const loading = toast.loading("In progress...");
        try {
            const { data } = await DeletBlog(ele._id);
            if (data.status !== "success") throw new Error("Intenal error");

            const deletedBlog = allBlog?.filter((el) => {
                return el._id !== ele._id
            });

            setAllBlog(deletedBlog);
            toast.dismiss(loading);
            toast.success("Deleted");
        } catch (err) {
            toast.dismiss(loading);
            toast.error(err.response.data);
        };
    };

    const hendleEdit = async (el, ind) => {
        console.log(el);
        setEditData({ ...el });
        setOpen(true);
        setEdit(true);
    };

    const hendleChangePage = (event, value) => {
        setPage(value);
    };

    useEffect(() => {

        AllBlog(limit, page);
    }, [page])
    return (
        <Paper variant='none' sx={{ backgroundColor: "white.blight" }} >

            <Box sx={{ bgcolor: "white.main", borderRadius: "10px", mb: 2 }}>
                <Grid item container sx={{ p: 2, justifyContent: "space-between" }} columns={{ xs: 8, sm: 4, md: 8 }} >
                    <Grid item sx={{ color: "secondary.main " }}>
                        <Typography variant="h6" gutterBottom sx={{ mt: 1, pl: 1, color: "text.main" }} >
                            Blog
                        </Typography>
                    </Grid>
                    <Grid item sx={{ color: "secondary.main " }}>
                        <IconButton color='primary' onClick={(e) => handleClickOpenAddModel()}><AiOutlinePlus /></IconButton>
                    </Grid>
                </Grid>
            </Box>
            {loading ? <Typography py={5} align="center"><Spenner /></Typography> : !open && <Box>
                {allBlog?.map((ele, ind) => {
                    return (
                        <Box sx={{ display: "flex", justifyContent: 'space-between', paddingX: '10px', backgroundColor: "white.main", py: 1, borderRadius: "10px", marginBottom: "10px", alignItems: "center" }} key={ind}>
                            <Typography variant='p' sx={{ width: '5%', textAlign: "center" }}>{ind + 1}.</Typography>
                            <Typography variant='p' sx={{ width: '35%', borderRight: "1px solid gray", fontWeight: "600" }}>{ele?.title?.slice(0, 15)} </Typography>
                            <Typography variant='p' sx={{ width: '45%', borderRight: "1px solid gray", fontWeight: "600" }}>{ele?.description?.slice(0, 20)}</Typography>
                            <Box sx={{ display: "flex", justifyContent: "space-between", width: "8%" }}>
                                <IconButton color='success' onClick={() => hendleEdit(ele, ind)}><BiEditAlt /></IconButton>
                                <IconButton color='error' onClick={() => hendleDelete(ele, ind)}><BiTrashAlt /></IconButton>
                            </Box>
                        </Box>
                    )
                })}
            </Box>}
                <Box sx={{ justifyContent: "center",mt:2 }}>
                    <Pagination sx={{ justifyContent: "center" }} showFirstButton showLastButton count={totalPages} page={page} onChange={hendleChangePage} variant="outlined" color="primary" />
                </Box>
            {open && <AddBlog setOpen={setOpen} setAllBlog={setAllBlog} allBlog={allBlog} editedData={editedData} setEdit={setEdit} edit={edit} />}
        </Paper>
    )
}
