import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	palette: {
		primary:{
			light:"#89bce6",
			main:"#4ba8f5",
			dark:"#167ee6",
		},
		secondary: {
			light: '#C3CED5',
			main: '#648090',
			dark: '#324048',
			exdark: '#A4B01C',
			contrastText: '#121212',
		},
		white: {
			blight: '#f5f6fa',
			light: '#eeeeee',
			main: '#ffffff',
			dark: '#bababa',
			contrastText: '#121212'
		},
		black: {
			light: '#a3a3a3',
			main: '#121212',
			dark: '#000000',
			contrastText: '#ffffff'
		},
		text: {
			white: '#ffffff',
			black: '#000000',
			light: '#808080',
			main: '#1e1e1e',
			dark: '#121212',
			contrastText: '#121212',
		}
	},
	components: {
		MuiPaper: {
			variants: [
				{
					props: { variant: 'none' },
					style: {
						boxShadow: 'none',
						borderRadius: 0
					},
				},
			]
		}
	}
});

export default theme;