import { Paper, Typography, Grid, Button, TableCell, Table, Box, TableHead, TableRow, TableContainer, TableBody, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { getUsers } from '../../../api';
import { BsEyeFill, BsPencilSquare, BsFillTrashFill } from 'react-icons/bs';
import { Delet } from '../../../api';
import { Spenner } from '../../../components';
import '../../../assets/css/User.css';
import { toast } from 'react-hot-toast';

const Users = () => {

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);


	const getusers = async () => {
		setLoading(true);
		try {
			const { data } = await getUsers();
			if (data.status !== "success") throw new Error(data.message);
			setData(data.data);
			setLoading(false);

		} catch (error) {
			console.log(error);
		};
	};

	const handleDelet = async (id) => {
		
		const loading = toast.loading("In Progress...");
		try {
			const { data:res } = await Delet(id);
			if (res.status !== "success") throw new Error(data.message);
			const deleted = data.filter((e) => {
				return e._id !== id;
			});
			toast.dismiss(loading);
			toast.success(`Deleted`)

			setData([...deleted]);
		
		} catch (error) {
			toast.dismiss(loading)
			toast.error("Error");
			console.log(error);
		};
	};

	useEffect(() => {
		getusers();
	}, []);

	return (
		<Paper variant='none' sx={{ backgroundColor: "white.blight" }} >
			<Box sx={{ bgcolor: "white.main", borderRadius: "10px", }}>
				<Grid item container sx={{ p: 2 }} >
					<Grid item xs={8} sx={{ color: "secondary.main" }} >
						<Typography variant="h6" gutterBottom sx={{ mt: 1, pl: 1, color: "text.main "}} >
							User Details
						</Typography>
					</Grid>
					<Grid item xs={4} sx={{ textAlign: "end" }}>
						{/* <Link to="add_user" style={{ textDecoration: "none", color: "black" }}><Button variant='contained' sx={{ marginTop: "5px", textTransform: "capitalize", color: "white.main", boxShadow: 0 }}>Add New User</Button></Link> */}
					</Grid>
				</Grid>
			</Box>

			<TableContainer sx={{ mt: 2, borderRadius: "10px", backgroundColor: "white.main" }} >
				<Table sx={{ minWidth: 600, }} aria-label="simple table">
					<TableHead>
						<TableRow sx={{ backgroundColor: "secondary.dark" }}>
							<TableCell sx={{ padding: "5px 16px", color: "white.main" }} align="left">User</TableCell>
							<TableCell sx={{ padding: "5px 16px", color: "white.main" }} align="left">Name</TableCell>
							<TableCell sx={{ padding: "5px 16px", color: "white.main" }} align="left">Email</TableCell>
							<TableCell sx={{ padding: "5px 16px", color: "white.main" }} align="left">Mobile</TableCell>
							<TableCell sx={{ padding: "5px 16px", color: "white.main" }} align="center">View</TableCell>
							<TableCell sx={{ padding: "5px 16px", color: "white.main" }} align="center">Edit</TableCell>
							<TableCell sx={{ padding: "5px 16px", color: "white.main" }} align="center">Delet</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>

						{loading ?<TableRow><TableCell align="center" colSpan={7}><Spenner /></TableCell></TableRow>: data.map((e, id) => {
							return (
								<TableRow key={id}>
									<TableCell align="left" sx={{ color: "secondary.dark", fontSize: "16px" }}>{id + 1}</TableCell>
									<TableCell align="left" sx={{ color: "secondary.dark", fontSize: "16px" }}>{e.fName}</TableCell>
									<TableCell align="left" sx={{ color: "secondary.dark", fontSize: "16px" }}>{e.email}</TableCell>
									<TableCell align="left" sx={{ color: "secondary.dark", fontSize: "16px" }}>{e.mobile}</TableCell>
									<TableCell align="center" ><Link to={`view/${e._id}`} ><IconButton color='secondary'><BsEyeFill /></IconButton></Link></TableCell>
									{/* <TableCell align="center" ><Link to={`edit/${e._id}`} ><IconButton color='success' size='small'><BsPencilSquare /></IconButton></Link></TableCell> */}
									<TableCell align="center"><IconButton color='error' onClick={() => handleDelet( e._id)} size='small'><BsFillTrashFill /></IconButton></TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
}

export default Users;