import { Box, Button, IconButton, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { EditInterest } from '../../../api';
import { MdOutlineAddAPhoto } from 'react-icons/md'
import Gallerymodel from '../../../components/GalleryModel/Gallerymodel';
import config from '../../../config/config';
import { toast } from 'react-hot-toast';

export default function EditDialog({ setOpen, editdata, nameofinterest, setUpdate, update }) {
    const [input, setInput] = useState({
        name:"",
        description:""
    });
    const [gallery, setGallery] = useState(false);
    const [imgurl, setImgUrl] = useState("");

    const handleChange = (e) => {
        const{name,value} =e.target;
        setInput({...input,[name]:value});
    };

    const handleSubmit = async () => {
        const loading = toast.loading("In progress...")
        const changeData = { name: input.name, icon: imgurl,description:input.description }
        try {
            const { data } = await EditInterest(editdata._id, changeData);
            if (data.status !== "success") throw new Error(data.message);
    
                const updatedItems = update.map(item => {
                  if (item._id === editdata._id) {
                    const updatedData =data.data;
                    return { ...item,name:updatedData.name,icon:updatedData.icon,description:updatedData.description };
                  }
                  return item;
                });
               
            
            // console.log(update);
            // const UpdateData = update.map((ele) => {
            //     console.log(ele);
            //     if (ele._id === editdata._id) {
            //         // const updated = ele.icons.filter((e) => {
            //         //     return e._id !== editdata._id;
            //         // });
            //        const updatedData =data.data;
            //         return {...update,name:updatedData.name,icon:updatedData.icon,description:updatedData.description}
            //     };
            //     return ele;
            // });
            console.log(updatedItems);
            toast.dismiss(loading);
            toast.success("Updated successfully");
            setUpdate(updatedItems);
            setInput("");
            setOpen(false);
        

        } catch (error) {
            toast.dismiss(loading);
            toast.error(error.message);
            console.log(error);
        };
    };

    useEffect(() => {
        setInput({name:editdata.name,description:editdata.description});
        setImgUrl(editdata.icon);
    }, []);

    return (
        <Box>

            <Box classname="dialog-box" sx={{ position: 'fixed', top: '0', left: '0', right: '0', bottom: '0', backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box classname="dialog-inner" sx={{ position: 'relative', borderRadius: "10px", bgcolor: 'white.main', maxWidth: '600px', width: '100%', py: 2, px: 3, }}>
                    <Typography sx={{ fontSize: "15px" }}>Update Interest</Typography>

                    <TextField sx={{ width: "100%", mt: 2 }} label="Add New" id="outlined-size-small" name="name" value={input.name} onChange={handleChange} size="small" InputLabelProps={{ shrink: true }} />
                    <TextField sx={{ width: "100%", mt: 2 }} label="description" id="outlined-size-small" name="description" value={input.description} onChange={handleChange} size="small" InputLabelProps={{ shrink: true }} />
                    <Box py={1} sx={{ alignItems: "center", display: "flex", gap: 4 }}>
                        <IconButton variant='contained' onClick={() => setGallery(true)} color='primary' size='large' ><MdOutlineAddAPhoto /></IconButton>
                        {imgurl && <img width="40%" height="150px" src={imgurl} alt="" />}
                    </Box>
                    <Button sx={{ mt: 2 }} variant='contained' onClick={handleSubmit}>Update</Button>
                    <Button sx={{ mt: 2 }} onClick={() => setOpen(false)}>close</Button>
                </Box>
            </Box>

            {gallery && <Gallerymodel setGallery={setGallery} setImgUrl={setImgUrl} />}

        </Box>
    )
}
