import { Box, Grid, IconButton, Paper, Typography } from '@mui/material'
import React, { useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai';
import ProductAdd from './ProductAdd';




export default function Product() {
    const [open, setOpen] = useState(false);

    const handleClickOpenAddModel = () => {
        setOpen(true);
    };
    return (
        <Paper variant='none' sx={{ backgroundColor: "white.blight" }} >
            <Box sx={{ bgcolor: "white.main", borderRadius: "10px", mb: 2 }}>
                <Grid item container sx={{ p: 2, justifyContent: "space-between" }} columns={{ xs: 8, sm: 4, md: 8 }} >
                    <Grid item sx={{ color: "secondary.main " }}>
                        <Typography variant="h6" gutterBottom sx={{ mt: 1, pl: 1, color: "text.main" }} >
                            Product
                        </Typography>
                    </Grid>
                    <Grid item sx={{ color: "secondary.main " }}>
                        <IconButton color='primary' onClick={handleClickOpenAddModel}><AiOutlinePlus /></IconButton>
                    </Grid>
                </Grid>
            </Box>
            <Box>
                {open && <ProductAdd/>}
            </Box>

        </Paper>
    )
}
