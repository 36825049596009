import axios from "axios";
import config from "../config/config";

const BASEURL = `${config.apiUrl}`;

export const getUsers = (params, headers) => axios.get(`${BASEURL}/user`, { params, headers });
export const getUser = (id,params, headers) => axios.get(`${BASEURL}/user/${id}`, { params, headers });
// export const userPost = (data, params, headers) => axios.post(`${BASEURL}/api/v1/auth/signup`, data, { params, headers });
export const Delet = (id,params, headers) => axios.delete(`${BASEURL}/user/${id}`, { params, headers });
// export const UpdateUser = (data,params, headers) => axios.patch(`${BASEURL}/api/v1/users`,data, { params, headers });

// export const getFilter = (params, headers) => axios.get(`${BASEURL}/api/v1/filters`, { params, headers });
// export const updateFilter = (id,data,params, headers) => axios.patch(`${BASEURL}/api/v1/filters/${id}`,data, { params, headers });
// export const addFilter = (data,params, headers) => axios.post(`${BASEURL}/api/v1/filters`,data, { params, headers });
// export const deletFilter = (id,params, headers) => axios.delete(`${BASEURL}/api/v1/filters/${id}`, { params, headers });

export const getInterest = (params, headers) => axios.get(`${BASEURL}/tecnology`, { params, headers });
export const addInterest = (data,params, headers) => axios.post(`${BASEURL}/tecnology`,data, { params, headers });
export const DeletInterest = (id,params, headers) => axios.delete(`${BASEURL}/tecnology/${id}`, { params, headers });
export const EditInterest = (id,data,params, headers) => axios.patch(`${BASEURL}/tecnology/${id}`,data, { params, headers });

export const getGallery = (params, headers) => axios.get(`${BASEURL}/gallery`, { params, headers });
export const addGallery = (data,params, headers) => axios.post(`${BASEURL}/gallery`,data, { params, headers });
export const Deletimg = (id,params, headers) => axios.delete(`${BASEURL}/gallery/${id}`, { params, headers });

export const getBlog = (limit,page,category,params, headers) => axios.get(`${BASEURL}/blog?page=${page}&limit=${limit}&${category}`, { params, headers });
export const addBlog = (data,params, headers) => axios.post(`${BASEURL}/blog`,data, { params, headers });
export const DeletBlog = (id,params, headers) => axios.delete(`${BASEURL}/blog/${id}`, { params, headers });
export const EditBlog = (id,data,params, headers) => axios.patch(`${BASEURL}/blog/${id}`,data, { params, headers });

export const getInqury = (limit,page,params, headers) => axios.get(`${BASEURL}/inqury?page=${page}&limit=${limit}`,{ params, headers });
export const singalInqury = (id,params, headers) => axios.get(`${BASEURL}/inqury/${id}`,{ params, headers });
export const deleteInqury = (id,params, headers) => axios.delete(`${BASEURL}/inqury/${id}`,{ params, headers });