import { useState } from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import '../../../assets/css/admin.css';
import { Header, Sidebar } from '../../../components';

const sidebarOpenState = localStorage.getItem('sidebarState') === 'false' ? false : true;
const AdminOutlet = () => {
	const [fullWidth, setFullWidth] = useState(sidebarOpenState);

	const handleClickMenuButton = () => {
		setFullWidth(prev => !prev);
		localStorage.setItem('sidebarState', !fullWidth);
	}

	return (
		<Box className='admin-container'>
			<Box className={`outlet-sidebar ${fullWidth ? 'open' : 'close'}`}>
				<Sidebar fullWidth={fullWidth} sidebarToggle={handleClickMenuButton} />
			</Box>
			<Box sx={{ width: '100%' }}>
				<Header sidebarToggle={handleClickMenuButton} />
				<Box className='outlet-conainer'>
					<Outlet />
				</Box>
			</Box>
		</Box>
	)
}

export default AdminOutlet;