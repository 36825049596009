import { ThemeProvider } from "@mui/material";
import { Toaster } from "react-hot-toast";
import { Routes, Route } from "react-router-dom";

import './App.css';
import { AdminOutlet, Dashboard, Filters, Gallery, Interest, Profile, Setting, Users } from "./pages/Admin";
import Filteroption from "./pages/Admin/Filters/Filteroption";
import NewUser from "./pages/Admin/Users/NewUser";
import Useredit from "./pages/Admin/Users/Useredit";
import Userview from "./pages/Admin/Users/Userview";
import { Home } from "./pages/Web";
import theme from "./theme/theme";
import Blog from "./pages/Admin/Blog/Blog";
import Product from "./pages/Admin/Product/Product";

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Toaster position="top-center" reverseOrder={false} />
            <Routes>
                
                <Route path="/" element={<AdminOutlet />}>
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="users" element={<Users />} />
                    <Route path="users/view/:id" element={<Userview />} />
                    <Route path="users/edit/:id" element={<Useredit />} />
                    <Route path="users/add_user" element={<NewUser />} />
                    <Route path="filters" element={<Filters />} />
                    <Route path="filters/:id" element={<Filteroption />} />
                    <Route path="blog" element={<Blog />} />
                    <Route path="product" element={<Product />} />
                    <Route path="interest" element={<Interest />} />
                    <Route path="gallery" element={<Gallery />} />
                    <Route path="profile" element={<Profile />} />
                </Route>
                <Route path="setting" element={<Setting />} />
            </Routes>

        </ThemeProvider>
    );
}

export default App;