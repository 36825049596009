
import { Box, Button, IconButton, Typography, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { GrFormClose } from 'react-icons/gr'
import { AiOutlinePlus } from 'react-icons/ai'
import config from '../../config/config';
import { BsCloudUploadFill } from 'react-icons/bs'
import Spenner from '../Spenner/Spenner';
import { addGallery, getGallery } from '../../api';
import { toast } from 'react-hot-toast';


export default function Gallerymodel({ setGallery, setImgUrl }) {


    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [uploadtab, setUploadTab] = useState(false);
    const [file, setFile] = useState([]);


    const getimg = async () => {
        try {
            setLoading(true)
            const { data } = await getGallery();

            if (data.status !== "success") throw new Error(data.message);

            setData(data.data);
            setLoading(false);

        } catch (error) {
            console.log(error);
        }
    }

    const handleChangeImg = (e) => {

        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {

            setFile(reader.result);
        };
        reader.onerror = err => {
            console.log(err);
        }
    };

    // const handleUploadImg = async () => {
    //     const loading = toast.loading("In progress")
    //     try {
    //         if (!file) {
    //             alert("please choose a file")
    //         }
    //         const formData = new FormData();
    //         file.forEach(image => {
    //             formData.append("file", image);
    //         });
    //         const { data: res } = await addGallery(formData);

    //         if (res.status !== "success") throw new Error(res.message);

    //         toast.dismiss(loading);
    //         toast.success("Upload successfully");
    //         const images = res.data;
    //         setData([...data, images])

    //     } catch (err) {
    //         toast.dismiss(loading);
    //         toast.error(err.message);
    //         console.log(err);
    //     };
    // };


    const handleUploadImg = async () => {
        const loading = toast.loading("In progress...");
        const url = {path:file}
        try {
            if (!file) {
                toast.error("please choose a file");
                toast.dismiss(loading);
            } else {
                const { data: res } = await addGallery(url);
                if (res.status !== "success") throw new Error(res.message);
                toast.dismiss(loading);
                toast.success("Upload successfully")
                const images = res.data;
                setData([...data, images]);
            };
        } catch (err) {
            toast.dismiss(loading);
            toast.error(err.message);
            console.log(err);
        };
    };

    const handleAdd = (ele) => {
        setImgUrl(ele.path);
        setGallery(false);
    };

    useEffect(() => {
        getimg();
    }, []);



    return (
        <Box classname="dialog-box" sx={{ position: 'fixed', top: '0', left: '0', right: '0', bottom: '0', backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

            <Box classname="dialog-inner" sx={{ position: 'relative', borderRadius: "10px", bgcolor: 'white.main', maxWidth: '800px', width: '100%', py: 2, px: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: "space-between" }}>
                    <Stack direction='row' spacing={2} alignItems='center'>
                        <Button variant={!uploadtab ? 'contained' : 'text'} color='primary' size='small' onClick={() => setUploadTab(false)}> Gallery</Button>
                        <Button variant={uploadtab ? 'contained' : 'text'} color='primary' onClick={() => setUploadTab(true)} size='small'> Upload</Button>
                    </Stack>
                    <Box >
                        <IconButton onClick={() => setGallery(false)}><GrFormClose /></IconButton>
                    </Box>
                </Box>
                {!uploadtab && <Box sx={{ overflowY: "auto", maxHeight: "300px", height: '100%', py: 2 }}  >
                    <Box mb={2} sx={{ borderRadius: "10px", display: "flex", gap: "2", justifyContent: "center", }} >
                        {loading ? <Typography py={5} align="center"><Spenner /></Typography> :
                            <Stack direction='row' gap={2} flexWrap='wrap' justifyContent='space-around'>
                                {data.map((ele, index) => {
                                    console.log(ele);
                                    return (
                                        <Box className="gallery-img" sx={{ bgcolor: "black.main", maxWidth: '200px', minWidth: '150px', width: '100%', height: '150px', borderRadius: "10px" }} key={index}>
                                            <img width="100%" height="150px" src={ele.path} alt="" style={{ objectFit: 'cover', borderRadius: "10px" }} />
                                            <Box id="img-btn"> <IconButton onClick={() => handleAdd(ele)} color='primary'><AiOutlinePlus /></IconButton></Box>
                                        </Box>
                                    );
                                })}
                            </Stack>}
                    </Box>
                </Box>}

                <Box sx={{ maxHeight: "300px", height: '100%', }}>
                    {uploadtab && <Box sx={{ bgcolor: "white.main", borderRadius: "10px", p: 2, mb: 2, textAlign: "center" }}>
                        <Box sx={{ border: "3px dotted black", textAlign: "center", padding: "15px 80px", mb: 3 }}>
                            <Typography sx={{ color: "gray", pb: 1 }}>Browse files to upload</Typography>
                            <input accept="image/*" style={{ display: 'none' }} id="raised-button-file" onChange={handleChangeImg} multiple type="file" />
                            <label htmlFor="raised-button-file">
                                <IconButton variant="raised" color='primary' size='large' component="span"><BsCloudUploadFill /></IconButton>
                            </label>
                            <Typography variant='body1' py={1}>
                                <strong>Limit : 12</strong>
                            </Typography>
                            <Button color="primary" variant='contained' onClick={handleUploadImg}>Upload File</Button>
                        </Box>
                    </Box>}
                </Box>
            </Box>
        </Box>
    )
}
