import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BsArrowLeft } from "react-icons/bs";
import { Link, useParams } from 'react-router-dom';
import { getUser } from '../../../api';
import config from '../../../config/config';
import Navmap from './Navmap';


export default function Userview() {

    const Api = config.apiUrl;
    const params = useParams();
    const [data, setData] = useState({});

    const getuser = async (id) => {
        try {
            const { data } = await getUser(id);
            if (data.status !== "success") throw new Error(data.message);
            setData(data.data);

        } catch (error) {
            console.log(error);
        };
    };

    useEffect(() => {
        getuser(params.id)
    }, [])

    return (
        <div>
            <Navmap />
            <Paper variant='none' sx={{ backgroundColor: "white.blight" }}  >
                <Box sx={{ bgcolor: "white.main", borderRadius: "10px", mb: 2 }}>
                    <Grid item container sx={{ p: 2, justifyContent: "space-between" }} columns={{ xs: 8, sm: 4, md: 8 }} >
                        <Grid item sx={{ color: "secondary.main" }}>
                            <Typography variant="h6" gutterBottom sx={{ mt: 1, pl: 1, color: "text.main" }} >
                                User Details
                            </Typography>
                        </Grid>
                        <Grid item sx={{ textAlign: "end", pt: 1 }}>
                            <Link to="/users" style={{ textDecoration: "none" }}><Button variant='outlined' ><BsArrowLeft style={{ marginRight: "2px" }} />Back</Button></Link>
                        </Grid>
                    </Grid>
                </Box>
                <Grid item container columns={{ xs: 4, sm: 8, md: 10 }} sx={{ justifyContent: "space-around", rowGap: "40px", borderRadius: "10px", backgroundColor: "white.main", p: 3 }}  >
                    <Grid item xs={4} >
                        <Typography variant="h6" sx={{ fontSize: "12px", color: "text.light" }}>First Name</Typography>
                        <Typography sx={{ fontSize: "17px" }}>{data?.name || "--"}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h6" sx={{ fontSize: "12px", color: "text.light" }}>Last Name</Typography>
                        <Typography sx={{ fontSize: "17px" }}>{data?.lName || "--"}</Typography>
                    </Grid>
                    <Grid item xs={4} >
                        <Typography variant="h6" sx={{ fontSize: "12px", color: "text.light" }}>Email</Typography>
                        <Typography sx={{ fontSize: "17px" }}>{data?.email || "--"}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h6" sx={{ fontSize: "12px", color: "text.light" }}>Mobile</Typography>
                        <Typography sx={{ fontSize: "17px" }}>{data?.mobile || "--"}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="h6" sx={{ fontSize: "12px", color: "text.light" }}>Gender</Typography>
                        <Typography sx={{ fontSize: "17px" }}>{data?.gender || "--"}</Typography>
                    </Grid>
                    {/* <Grid item xs={5}>
                        <Typography variant="h6" sx={{ fontSize: "12px", color: "text.light" }}>Fb Id</Typography>

                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="h6" sx={{ fontSize: "12px", color: "text.light" }}>Fb Userid</Typography>

                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="h6" sx={{ fontSize: "12px", color: "text.light" }}>Gender</Typography>
                        <Typography sx={{ fontSize: "17px" }}>{data?.gender?.text || "--"}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="h6" sx={{ fontSize: "12px", color: "text.light" }}>Gender Intrest</Typography>

                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="h6" sx={{ fontSize: "12px", color: "text.light" }}>Health Condition</Typography>
                        <Box sx={{ display: "flex" }}>
                            {data?.healthCondition?.map((e, id) => <Typography key={id} sx={{ pr: 2, fontSize: "17px" }} >{e?.text},</Typography>) || "--"}
                        </Box>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="h6" sx={{ fontSize: "12px", color: "text.light" }}>Interested Health Condition</Typography>
                        <Typography sx={{ display: "flex" }}>
                            {data?.interestedHealthCondition?.map((e, id) => <Typography key={id} sx={{ pr: 2, fontSize: "17px" }} >{e?.text},</Typography>) || "--"}
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="h6" sx={{ fontSize: "12px", color: "text.light" }}>Religion</Typography>

                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="h6" sx={{ fontSize: "12px", color: "text.light" }}>Intrested Religion</Typography>

                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="h6" sx={{ fontSize: "12px", color: "text.light" }}>Education</Typography>

                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="h6" sx={{ fontSize: "12px", color: "text.light" }}>Profession</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="h6" sx={{ fontSize: "12px", color: "text.light" }}>User Intrests</Typography>
                        <Stack direction={{ xs: 'column', sm: 'row' }}
                            spacing={{ xs: 1, sm: 2, md: 4 }}>
                            {data?.userIntrests?.map((e, id) => {
                                return <Box sx={{ display: "flex", alignItems: "center", pt: 1 }}>
                                    <Avatar alt="Remy Sharp" sx={{ width: "25px", height: "25px", borderRadius: "15px", objectFit: "cover" }} src={`${Api}${e.icon}`} />
                                    <Typography sx={{ pl: 1, fontSize: "17px" }}>{e.text},</Typography>
                                </Box>
                            })}
                        </Stack>

                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="h6" sx={{ fontSize: "12px", color: "text.light" }}>Height</Typography>
                        <Typography sx={{ fontSize: "17px" }}>{data?.height || "--"}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="h6" sx={{ fontSize: "12px", color: "text.light" }}>Personality</Typography>

                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="h6" sx={{ fontSize: "12px", color: "text.light" }}>Drinking Habits</Typography>

                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="h6" sx={{ fontSize: "12px", color: "text.light" }}>Smoking Habits</Typography>

                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant="h6" sx={{ fontSize: "12px", color: "text.light" }}>Smoking Habits</Typography>

                    </Grid>
 */}

                </Grid>

                <Box sx={{ bgcolor: "white.main", borderRadius: "10px", my: 2 }}>
                    <Grid item container sx={{ p: 2, justifyContent: "space-between" }} columns={{ xs: 8, sm: 4, md: 8 }} >
                        <Grid item sx={{ color: "secondary.main" }}>
                            <Typography variant="h6" gutterBottom sx={{ mt: 1, pl: 1, color: "text.main" }} >
                                User Posts
                            </Typography>
                        </Grid>
                        <Grid item sx={{ textAlign: "end", pt: 1 }}>
                            <Link to="/users" style={{ textDecoration: "none" }}><Button variant='outlined' ><BsArrowLeft style={{ marginRight: "2px" }} />Back</Button></Link>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ display: "flex", flexWrap: "warp", gap: 3, }}>
                    {data?.posts?.map((ele, index) => {
                        return (
                            <Box key={index} sx={{ p: 1.5, bgcolor: "white.main", borderRadius: "10px" }}>
                                <img style={{ objectFit: "cover", maxHeight: "300px", maxWidth: "300px" }} src={`${Api}${ele.image}`} alt="" />
                            </Box>
                        )
                    })
                    }
                </Box>

            </Paper>
        </div>
    )
}
