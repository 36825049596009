import { Box, Grid, IconButton, Pagination, Paper, Typography, colors } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { FiEye } from 'react-icons/fi'
import { deleteInqury, getInqury } from '../../../api';
import { BiTrashAlt } from 'react-icons/bi';
import { toast } from 'react-hot-toast';
import InquryView from './InquryView';
import { Spenner } from '../../../components';

export default function Filters() {
	const [inqury, setInqury] = useState([]);
	const [inquryId, setInquryId] = useState('');
	const [loading, setLoading] = useState(true);
	const [view, setView] = useState(false);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const limit = 2

	const allInqury = async (limit, page) => {
		try {
			const { data } = await getInqury(limit, page);
			setInqury(data.data);
			setTotalPages(data.totalPages);
			setLoading(false);
		} catch (err) {
			console.log(err);
		};
	};

	const hendleChangePage = (event, value) => {
		setPage(value);
	};

	const hendleDelete = async (ele, id, ind) => {
		const loading = toast.loading("in progres...")
		try {
			const { data } = await deleteInqury(ele._id);
			if (data.status === "success") {
				const deletedBlog = inqury.filter((element) => {
					return ele._id !== element._id
				});
				setInqury(deletedBlog);
				toast.dismiss(loading);
				toast.success("Deleted successfully");
			} else {
				toast.dismiss(loading);
				toast.error("Internal Error");
			};
		} catch (err) {
			console.log(err);
		};
	};

	const hendleView = (ele, ind) => {
		setView(true);
		setInquryId(ele._id);
	}


	useEffect(() => {
		allInqury(limit, page);
	}, [page])
	return (
		<Paper variant='none' sx={{ backgroundColor: "white.blight" }} >
			<Box sx={{ bgcolor: "white.main", borderRadius: "10px", mb: 2 }}>
				<Grid item container sx={{ p: 2, justifyContent: "space-between" }} columns={{ xs: 8, sm: 4, md: 8 }} >
					<Grid item sx={{ color: "secondary.main " }}>
						<Typography variant="h6" gutterBottom sx={{ mt: 1, pl: 1, color: "text.main" }} >
							Inqury From
						</Typography>
					</Grid>
				</Grid>
			</Box>

			{loading ? <Typography py={5} align="center"><Spenner /></Typography> : inqury.map((ele, ind) => {
				return (
					<Box sx={{ display: "flex", justifyContent: 'space-between', paddingX: '10px', backgroundColor: "white.main", py: 1, borderRadius: "10px", marginBottom: "10px", alignItems: "center" }} key={ind} >
						<Typography variant='p' sx={{ width: '5%', textAlign: "center" }}>{ind + 1}.</Typography>
						<Typography variant='p' sx={{ width: '35%', borderRight: "1px solid gray", fontWeight: "600" }}>{ele.name}</Typography>
						<Typography variant='p' sx={{ width: '45%', borderRight: "1px solid gray", fontWeight: "600" }}>{ele.email}</Typography>
						<Box sx={{ display: "flex", justifyContent: "space-between", width: "8%" }}>
							<IconButton color="success" onClick={() => hendleView(ele, ind)}><FiEye /></IconButton>
							<IconButton color='error' onClick={() => hendleDelete(ele, ind)}><BiTrashAlt /></IconButton>
						</Box>
					</Box>
				)
			})}
			<Pagination sx={{ justifyContent: "center", mt: 2 }} showFirstButton showLastButton count={totalPages} page={page} onChange={hendleChangePage} variant="outlined" color="primary" />
			{view && <InquryView setView={setView} inquryId={inquryId} setAllInqury={setInqury} allinqury={inqury} />}
		</Paper>
	)
}
