// import { useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { HiOutlineMenuAlt4 } from 'react-icons/hi';
import { VscDashboard } from 'react-icons/vsc';
// import { IoClose } from 'react-icons/io5';
import { FiUsers } from 'react-icons/fi';
import { BsFillGearFill } from 'react-icons/bs';
import { AiFillFire } from 'react-icons/ai';
import { GrGallery } from 'react-icons/gr';
import { FaBlogger, FaFilter, FaUserAlt } from 'react-icons/fa';
import '../../assets/css/sidebar.css';
import useStyles from '../../assets/jss/sidebar';

const Sidebar = ({ fullWidth, sidebarToggle }) => {
	const classes = useStyles();

	const handleClick = () => {
		sidebarToggle();
	}

	return (
		<Box className={['sidebar-container', classes.sidebarContainer]}>
			<Box className='sidebar-header'>
				<Box>
					<Typography variant='h5' component='h1'>{fullWidth ? "GENUTIVE" : "G"}</Typography>
				</Box>
				<Box onClick={handleClick}><IconButton size={fullWidth ? 'medium' : 'small'}><HiOutlineMenuAlt4 /></IconButton></Box>
				{/* <Box onClick={handleClick}><IconButton>{!fullWidth ? <HiOutlineMenuAlt4 /> : <IoClose />}</IconButton></Box> */}
			</Box>
			<Box className='sidebar-body'>
				{/* <Box className='sidebar-account'>
				</Box> */}
				<Box className='sidebar-menu-container'>
					<ListItem name='Dashboard' link='dashboard' icon={<VscDashboard />} fullSidebar={fullWidth} />
					<ListItem name='Users' link='users' icon={<FiUsers />} fullSidebar={fullWidth} />
					<ListItem name='Filters' link='filters' icon={<FaFilter />} fullSidebar={fullWidth} />
					<ListItem name='blog' link='blog' icon={<FaBlogger />} fullSidebar={fullWidth} />
					<ListItem name='product' link='product' icon={<FaBlogger />} fullSidebar={fullWidth} />
					<ListItem name='Interest' link='interest' icon={<AiFillFire />} fullSidebar={fullWidth} />
					<ListItem name='Gallery' link='gallery' icon={<GrGallery />} fullSidebar={fullWidth} />
					<ListItem name='Profile' link='profile' icon={<FaUserAlt />} fullSidebar={fullWidth} />
					<ListItem name='Setting' link='setting' icon={<BsFillGearFill />} fullSidebar={fullWidth} />
				</Box>
			</Box>
		</Box>
	)
}

export default Sidebar;

const ListItem = ({ name, icon, link, fullSidebar }) => (
	<NavLink to={`/${link}`} className={({ isActive }) => isActive ? 'sidebar-menu-link active' : 'sidebar-menu-link'}>
		<Typography variant='body1' component='li'>
			<Box className='sidebar-menu-icon'>{icon}</Box>
			{fullSidebar && <Typography className='sidebar-menu-text' variant='body1' component='p'>{name}</Typography>}
		</Typography>
	</NavLink>
);