import { Paper, Box, Typography, Grid, IconButton, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { AiOutlinePlus } from "react-icons/ai"
import { BiEdit } from "react-icons/bi"
import { BiTrash } from "react-icons/bi"
import { DeletInterest, getInterest } from '../../../api';
import { Spenner } from '../../../components';
import config from '../../../config/config';
import Dialog from './Dialog';
import EditDialog from './EditDialog';

export default function Interest() {

    const Api = config.imageBaseUrl;

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [editopen, setEditOpen] = useState(false);
    const [nameofinterest, setNameofInterest] = useState("");
    const [editdata, setEditdata] = useState("");

    const getinterest = async () => {
        try {
            const { data } = await getInterest();
            if (data.status !== "success") throw new Error(data.message);
            setData(data.data);
            setLoading(false);

        } catch (error) {
            console.log(error);
        };
    };

    useEffect(() => {
        getinterest();
    }, []);

    const handleClickOpenAddModel = () => {
        setOpen(true);
    };

    const handleDelete = async (element, name) => {
        const loading = toast.loading("In progress...")
        try {
            const { data: res } = await DeletInterest(element._id);
            if (res.status !== "success") throw new Error(data.message);

            // const newData = data.map((ele) => {
            //     if (ele._id === name) {
                    const deleted = data.filter((e) => {
                        return e._id !== element._id;
                    });
                //     ele.icons = [...deleted];
                //     return ele
                // };
            //     return ele;
            // });
            toast.dismiss(loading);
            toast.success("Deleted");
            setData(deleted);

        } catch (error) {
            toast.dismiss(loading);
            toast.error(error.message);
            console.log(error);
        };
    };

    const handleEdit = async (ele, name) => {
        setEditOpen(true);
        setEditdata(ele);
        setNameofInterest(name);
    };

    return (
        <Paper variant='none' sx={{ backgroundColor: "white.blight" }} >

            <Box sx={{ bgcolor: "white.main", borderRadius: "10px", mb: 2 }}>
                <Grid item container sx={{ p: 2, justifyContent: "space-between" }} columns={{ xs: 8, sm: 4, md: 8 }} >
                    <Grid item  sx={{ color: "secondary.main " }}>
                        <Typography variant="h6" gutterBottom sx={{ mt: 1, pl: 1, color: "text.main" }} >
                            Interest
                        </Typography>
                    </Grid>  
                    <Grid item  sx={{ color: "secondary.main " }}>
                    <IconButton color='primary' onClick={(e) => handleClickOpenAddModel()}><AiOutlinePlus /></IconButton>
                    </Grid>
                </Grid>
            </Box>
            <Stack spacing={2}>
            {loading ? <Typography py={5} align="center"><Spenner /></Typography> : data?.map((ele, index) => {
                    return (
                        <Box  sx={{ bgcolor: "white.main", borderRadius: "10px", p: 2 }}>
                            <Box sx={{ bgcolor: "white.main", borderRadius: "10px", mb: 2 }}>
                                <Grid item container justifyContent='space-between' columns={{ xs: 8, sm: 4, md: 8 }} >
                                    <Grid item sx={{ color: "secondary.main" }}>
                                    <Box key={index} sx={{ display: "flex", alignItems: "center", px: 1.5, py: 1, bgcolor: 'white.blight', borderRadius: '100px' }}>
                                            <img src={ele.icon} alt="" style={{ width: "25px", height: "25px", borderRadius: "15px", objectFit: "cover" }} />
                                            <Typography variant='body2' sx={{ mx: 1, color: "text.main", fontSize: "14px" }} >{ele.name}</Typography>
                                            <Typography variant='p' sx={{ mx: 1, color: "text.main", fontSize: "14px" }} >{ele.description}</Typography>
                                            <IconButton color="success" onClick={() => handleEdit(ele)} size='small'><BiEdit /></IconButton>
                                            <IconButton color="error" onClick={() => handleDelete(ele)} size='small'><BiTrash /></IconButton>
                                        </Box>
                                    </Grid>
                                    <Grid item sm={1} sx={{ color: "secondary.main", textAlign: "center" }}>
                                       
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    )
                })}

                {/* {loading ? <Typography py={5} align="center"><Spenner /></Typography> : data?.map((ele, index) => {
                    return ( */}
                        {/* <Box  sx={{ bgcolor: "white.main", borderRadius: "10px", p: 2 }}> */}
                            {/* <Box sx={{ bgcolor: "white.main", borderRadius: "10px", mb: 2 }}>
                                <Grid item container justifyContent='space-between' columns={{ xs: 8, sm: 4, md: 8 }} >
                                    <Grid item sx={{ color: "secondary.main" }}>
                                        <Typography variant="h6" gutterBottom textTransform="capitalize">{ele._id}</Typography>
                                    </Grid>
                                    <Grid item sm={1} sx={{ color: "secondary.main", textAlign: "center" }}>
                                        <IconButton color='primary' onClick={(e) => handleClickOpenAddModel(e, ele._id)}><AiOutlinePlus /></IconButton>
                                    </Grid>
                                </Grid>
                            </Box> */}
                            {/* <Box mb={2} sx={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
                                {.icons?.map((element, index) => {
                                    return (
                                        <Box key={index} sx={{ display: "flex", alignItems: "center", px: 1.5, py: 1, bgcolor: 'white.blight', borderRadius: '100px' }}>
                                            <img src={`${Api}${element.icon}`} alt="" style={{ width: "25px", height: "25px", borderRadius: "15px", objectFit: "cover" }} />
                                            <Typography variant='body2' sx={{ mx: 1, color: "text.main", fontSize: "14px" }} >{element.text}</Typography>
                                            <IconButton color="success" onClick={() => handleEdit(element)} size='small'><BiEdit /></IconButton>
                                            <IconButton color="error" onClick={() => handleDelete(element)} size='small'><BiTrash /></IconButton>
                                        </Box>
                                    )
                                })}
                            </Box>
                        </Box> */}
                    {/* )
                })} */}
            </Stack>
            {open && <Dialog setOpen={setOpen} nameofinterest={nameofinterest} setAddNew={setData} addnew={data} />}
            {editopen && <EditDialog setOpen={setEditOpen} editdata={editdata} nameofinterest={nameofinterest} setUpdate={setData} update={data} />}
        </Paper>
    );
};


