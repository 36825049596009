import { Box, Button, Grid, IconButton, Paper, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { AiOutlinePlus } from "react-icons/ai";
import { BsArrowLeft, BsCloudUploadFill, BsFillTrashFill } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { Deletimg, addGallery, getGallery } from '../../../api';
import "../../../assets/css/gallery.css";
import { Spenner } from '../../../components';
import config from '../../../config/config';

export default function Gallery() {

    const [file, setFile] = useState('');
    const [uploadBox, setUploadBox] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [base64, setBase64] = useState('');



    const handleChange = (e) => {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {

            setFile(reader.result);
        };
        reader.onerror = err => {
            console.log(err);
        }

        // const files = e.target.files;
        // if (files.length === 0) return;

        // const images = [];
        // for (let i = 0; i < files.length; i++) {
        //     const image = files[i];
        //     images.push(image);
        // }
        // setFile(images);
    };

    const getimg = async () => {
        try {
            setLoading(true)
            const { data } = await getGallery();
            if (data.status !== "success") throw new Error(data.message);
            setData(data.data);
            setLoading(false);
            
        } catch (error) {
            console.log(error);
        };
    };

    const handleUpload = async () => {
        const loading = toast.loading("In progress...");
        const url = {path:file}
        try {
            if (!file) {
                toast.error("please choose a file");
                toast.dismiss(loading);
            } else {
                const { data: res } = await addGallery(url);
                if (res.status !== "success") throw new Error(res.message);
                toast.dismiss(loading);
                toast.success("Upload successfully");
                const images = res.data;
                setData([...data, images]);
            };
        } catch (err) {
            toast.dismiss(loading);
            toast.error(err.message);
            console.log(err);
        };
    };

    const handleDelet = async (id) => {
        const loading = toast.loading("In progress...");
        const deleted = data.filter((e) => {
            return e._id !== id;
        });
        try {
            const { data } = await Deletimg(id);
            if (data.status !== "success") throw new Error(data.message);
            toast.dismiss(loading);
            toast.success("Deleted");
            setData(deleted);

        } catch (error) {
            toast.dismiss(loading);
            toast.error(error.message);
            console.log(error);
        };
    };

    useEffect(() => {
        getimg();
    }, []);

    return (

        <Paper variant='none' sx={{ backgroundColor: "white.blight" }} >
            <Box sx={{ bgcolor: "white.main", borderRadius: "10px", mb: 2 }}>
                <Grid container sx={{ p: 2, justifyContent: "space-between" }} columns={{ xs: 8, sm: 4, md: 8 }} >
                    <Grid item sx={{ color: "secondary.main" }}>
                        <Typography variant="h6" gutterBottom sx={{ mt: 1, pl: 1, color: 'text.main' }} >
                            Gallery
                        </Typography>
                    </Grid>
                    <Grid item sx={{ textAlign: "end", pt: 1 }}>
                        <IconButton color='primary' onClick={() => setUploadBox(true)} sx={{ marginRight: "25px" }} ><AiOutlinePlus /></IconButton>
                        <Link to="/dashboard" style={{ textDecoration: "none" }}><Button variant='outlined' ><BsArrowLeft style={{ marginRight: "2px" }} />Back</Button></Link>
                    </Grid>
                </Grid>
            </Box>

            {uploadBox && <Box sx={{ bgcolor: "white.main", borderRadius: "10px", p: 2, mb: 2, textAlign: "center" }}>
                <Box sx={{ border: "3px dotted black", textAlign: "center", padding: "15px 80px", mb: 3 }}>
                    <Typography sx={{ color: "gray", pb: 1 }}>Browse files to upload</Typography>
                    <input accept="image/*" style={{ display: 'none' }} id="raised-button-file" onChange={handleChange} multiple type="file" />
                    <label htmlFor="raised-button-file">
                        <IconButton variant="raised" color='primary' size='large' component="span"><BsCloudUploadFill /></IconButton>
                    </label>
                    <Typography variant='body1' py={1}>
                        <strong>Limit : 12</strong>
                    </Typography>
                    <Button color="primary" variant='contained' onClick={handleUpload}>Upload File</Button>
                    <Button sx={{ marginLeft: "15px" }} onClick={() => setUploadBox(false)} color="primary" >Close</Button>
                </Box>
            </Box>}

            <Box mb={2} sx={{ borderRadius: "10px", display: "flex", gap: "2", justifyContent: "center" }} >
                {loading ? <Typography py={5} align="center"><Spenner /></Typography> :
                    <Stack direction='row' gap={2} flexWrap='wrap' justifyContent='space-around' >
                        {data.map((ele, index) => {
                            return (
                                <Box className="gallery-img" sx={{ bgcolor: "black.main", maxWidth: '300px', minWidth: '240px', width: '100%', height: '200px', borderRadius: "10px" }} key={index}>
                                    <img width="100%" height="200px" src={`${ele.path}`} alt="" style={{ objectFit: 'cover', borderRadius: "10px" }} />
                                    <Box id="img-btn"> <IconButton onClick={() => handleDelet(ele._id)} color='error'><BsFillTrashFill /></IconButton></Box>
                                </Box>
                            );
                        })}
                    </Stack>}
            </Box>

        </Paper>

    )
}
