import { Paper, Grid, Typography, TableCell, Table, TableHead, TableRow, TableContainer, TableBody, Box, Stack } from '@mui/material';
import { useTheme } from '@mui/styles';
import { BsGraphUp, BsFillEmojiHeartEyesFill, BsFillPeopleFill, BsFillPersonBadgeFill } from 'react-icons/bs';




const Dashboard = () => {
	const data = [
		{
			contry: "Australia",
			clients: "Beavis",
			changes: "2.43%",
			budget: "$1478",
			stutas: "Active",
		},
		{
			contry: "Australia",
			clients: "Beavis",
			changes: "2.43%",
			budget: "$1478",
			stutas: "Active",
		},
		{
			contry: "Australia",
			clients: "Beavis",
			changes: "2.43%",
			budget: "$1478",
			stutas: "Active",
		},
		{
			contry: "Australia",
			clients: "Beavis",
			changes: "2.43%",
			budget: "$1478",
			stutas: "Active",
		},
		{
			contry: "Australia",
			clients: "Beavis",
			changes: "2.43%",
			budget: "$1478",
			stutas: "Active",
		},

	]



	const themes = useTheme();
	const theme = themes.palette;
	return (

		<Paper variant='none' sx={{ backgroundColor: theme.white.blight, pt: 4 }} >
			<Grid container spacing={{ xs: 2, md: 4 }} columns={{ xs: 2, sm: 8, md: 12 }} sx={{ justifyContent: "space-evenly" }} >

				<Grid xs={2} sx={{ display: "flex", border: "1px solid #167ee6", borderRadius: "5px", p: 1, backgroundColor: theme.white.main }}>

					<Typography variant='h5' sx={{ m: 0, backgroundColor: "#167ee6" }} >
						<BsGraphUp style={{ fontSize: "40px", paddingTop: "8px", margin: "10px", color: "white" }} />
					</Typography>
					<Typography variant="h6" sx={{ fontSize: "17px", color: theme.text.main, pl: 2, pt: 1, textAlign: "center" }} >
						Views <br />1000
					</Typography>

				</Grid>

				<Grid xs={2} sx={{ display: "flex", border: "1px solid #4caf50", borderRadius: "5px", p: 1, backgroundColor: theme.white.main }}>
					<Typography variant='h5' sx={{ m: 0, backgroundColor: "#4caf50" }} >
						<BsFillEmojiHeartEyesFill style={{ fontSize: "40px", paddingTop: "8px", margin: "10px", color: "white" }} />
					</Typography>
					<Typography variant="h6" sx={{ fontSize: "17px", color: theme.text.main, pl: 2, pt: 1, textAlign: "center" }} >
						Eranings <br />5000 $
					</Typography>

				</Grid>

				<Grid xs={2} sx={{ display: "flex", border: "1px solid #4c5caf", borderRadius: "5px", p: 1, backgroundColor: theme.white.main }}>
					<Typography variant='h5' sx={{ m: 0, backgroundColor: "#4c5caf" }} >
						<BsFillPeopleFill style={{ fontSize: "40px", paddingTop: "8px", margin: "10px", color: "white" }} />
					</Typography>
					<Typography variant="h6" sx={{ fontSize: "17px", color: theme.text.main, pl: 2, pt: 1, textAlign: "center" }} >
						Users <br />5000
					</Typography>

				</Grid>

				<Grid xs={2} sx={{ display: "flex", border: "1px solid #da0c4d", borderRadius: "5px", p: 1, backgroundColor: theme.white.main }}>
					<Typography variant='h5' sx={{ m: 0, backgroundColor: "#da0c4d" }} >
						<BsFillPersonBadgeFill style={{ fontSize: "40px", paddingTop: "8px", margin: "10px", color: "white" }} />
					</Typography>
					<Typography variant="h6" sx={{ fontSize: "17px", color: theme.text.main, pl: 2, pt: 1, textAlign: "center" }} >
						Enquiry <br />5000
					</Typography>

				</Grid>
			</Grid>
			{/*<Grid container>
					<Grid xs={6} sx={{ bgcolor: theme.primary.dark, mt: 4, mr: 1, borderRadius: "10px" }}>
						<Grid xs={7}>
							<Typography variant="h6" gutterBottom sx={{ mt: 1, pl: 1, width: "100%" }} >
								Country Campaigns
							</Typography>
						</Grid>
						<Grid xs={12}>
							<Box sx={{ padding: "10px" }} >
								<TableContainer component={Paper} >
									<Table sx={{ minWidth: 550, }} aria-label="simple table">
										<TableHead >
											<TableRow sx={{ backgroundColor: theme.primary.main, color: "red" }}>
												<TableCell align="left">COUNTRY</TableCell>
												<TableCell align="left">CLIENT</TableCell>
												<TableCell align="left">CHANGES</TableCell>
												<TableCell align="left">BUDGET</TableCell>
												<TableCell align="left">STATUS</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{data.map((e, id) => {
												return (
													<TableRow key={id}>
														<TableCell align="left">{e.contry}</TableCell>
														<TableCell align="left">{e.clients}</TableCell>
														<TableCell align="left">{e.changes}</TableCell>
														<TableCell align="left" >{e.budget}</TableCell>
														<TableCell align="left" >{e.stutas}</TableCell>

													</TableRow>
												)
											})}

										</TableBody>
									</Table>
								</TableContainer>
							</Box>
						</Grid>
					</Grid>
					<Grid xs={6} sx={{ bgcolor: theme.primary.dark, mt: 4, ml: 1, borderRadius: "10px" }}>
						<Grid xs={7}>
							<Typography variant="h6" gutterBottom sx={{ mt: 1, pl: 1, width: "100%", }} >
								Country Campaigns
							</Typography>
						</Grid>
						<Grid xs={12}>
							<Box sx={{ padding: "10px" }} >
								<TableContainer component={Paper} >
									<Table sx={{ minWidth: 550, }} aria-label="simple table">
										<TableHead >
											<TableRow sx={{ backgroundColor: theme.primary.main, color: "red" }}>
												<TableCell align="left">COUNTRY</TableCell>
												<TableCell align="left">CLIENT</TableCell>
												<TableCell align="left">CHANGES</TableCell>
												<TableCell align="left">BUDGET</TableCell>
												<TableCell align="left">STATUS</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{data.map((e, id) => {
												return (
													<TableRow key={id}>
														<TableCell align="left">{e.contry}</TableCell>
														<TableCell align="left">{e.clients}</TableCell>
														<TableCell align="left">{e.changes}</TableCell>
														<TableCell align="left" >{e.budget}</TableCell>
														<TableCell align="left" >{e.stutas}</TableCell>

													</TableRow>
												)
											})}

										</TableBody>
									</Table>
								</TableContainer>
							</Box>
						</Grid>
					</Grid>
				</Grid>
				<UserDetails />*/}
		</Paper>

	)
}

export default Dashboard;