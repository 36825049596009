import React, { useEffect, useMemo, useState } from 'react'
import './profiles.css'
import { BsDownload, BsFillTagFill } from 'react-icons/bs'
import { Box, Button, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Pagination, Select, Switch, TablePagination, TextField } from '@mui/material'
import { AiOutlineCloseCircle, AiOutlinePlusSquare } from 'react-icons/ai'
import { BiDownArrowAlt, BiEditAlt, BiUpArrowAlt } from 'react-icons/bi'
import { FaTrash } from 'react-icons/fa'



const width = [{
	c1:"30%",
	c2:"10%",
	c3:"10%",
	c4:"10%",
	c5:"10%",
	c6:"10%",
	c7:"10%",
	c8:"10%",
	c9:"10%",
	c10:"5%",
	c11:"5%",
}]

export default function Profile() {
	const [openAdd, setOpenAdd] = useState(false);
	const [addInput, setAddInput] = useState({
		cName: "",
		composition: "",
		product: "",
		type: "",
		packSize: "",
		gst: '',
		hsn: "",
		category: "",
		active: '',
		sku: ''
	});
	const [sorting, setSorting] = useState({ field: 'name', ascending: false });
	const [addData, setAddData] = useState([]);
	const [editData, setEditData] = useState(false);
	const [editIndex, setEditIndex] = useState("");
	const [shortingIcon, setSortingIcon] = useState(false);
	const [page, setpage] = useState(0);
	const [pageSize, setPageSize] = useState(5);


	const handleChangeAddInput = (e) => {
		const { name, value } = e.target;
		setAddInput({ ...addInput, [name]: value });
	};

	const handleAddClose = () => {
		setOpenAdd(false);
	};

	const handleAddBlock = () => {
		setOpenAdd(true);
	};

	const handleAddSave = () => {
		setAddData([...addData, addInput]);
		setAddInput(" ");
		setOpenAdd(false);
	};


	// table data edit

	const handleEdit = (val, key) => {
		setOpenAdd(true);
		setAddInput(val);
		setEditData(true);
		setEditIndex(key);
	};

	const handleAddEdit = () => {
		const update = addData?.map((ele, ind) => ind === editIndex ? addInput : ele);
		console.log(update);
		setAddData(update);
		setOpenAdd(false);
	};

	const handleDelete = (key) => {
		const deleteAddData = addData?.filter((e, index) => {
			return index !== key
		});
		setAddData(deleteAddData);
	};

	// sorting logoc

	const applySorting = (key, ascending) => {
		setSorting({ field: key, ascending: ascending });

		setSortingIcon(true)
	};

	// pagination of table

	const handleChangePage = (event, newpage) => {
		setpage(newpage);
	};

	const handleChangeRowsPerPage = (event) => {
		setPageSize(parseInt(event.target.value, 10));
		setpage(0);
	};

	const handleSearch = () => {

	};

	useEffect(() => {

		const currentUsersCopy = [...addData];

		// Apply sorting
		const sortedCurrentUsers = currentUsersCopy.sort((a, b) => {
			return a[sorting.field]?.localeCompare(b[sorting.field]);

		});
		setAddData(sorting.ascending ? sortedCurrentUsers : sortedCurrentUsers.reverse());
	}, [sorting]);
	return (
		<>
			<div className='product-head'>
				<h2>Products</h2>
				<div>
					<IconButton variant="contained" color='primary'><BsDownload /></IconButton>
				</div>
			</div>
			<div className='product-button-group'>
				<Button className='product-button' variant="contained" color='primary'>Import Offers</Button>
				<Button className='product-button' variant="contained" color='primary'>Import product</Button>
				<Button className='product-button' variant="contained" color='primary'>Import Offers</Button><Button className='product-button' variant="contained" color='primary'>Import product</Button>
				<Button className='product-button' variant="contained" color='primary'>Import Offers</Button>

				<IconButton sx={{ width: "5%" }} variant="contained" color='primary' onClick={handleAddBlock} ><AiOutlinePlusSquare /></IconButton>

			</div>
			<div className="product-table table-responsive">
				<table id='product-table' className="table">
					<tr>
						<th style={{width:`${width[0].c1}`}}  >Action</th>
						<th style={{width:`${width[0].c2}`}} onClick={() => applySorting('product', !sorting.ascending)}>Product { sorting.ascending  ?  shortingIcon && sorting.field ==="product" && <span><BiUpArrowAlt /></span> :  shortingIcon && sorting.field ==="product" && <span><BiDownArrowAlt /></span>}</th>
						<th style={{width:`${width[0].c3}`}} onClick={() => applySorting('cName', !sorting.ascending)}>Company Name {sorting.ascending  ? shortingIcon && sorting.field ==="cName" && <span><BiUpArrowAlt /></span> : shortingIcon && sorting.field ==="cName" &&<span><BiDownArrowAlt /></span>}</th>
						<th style={{width:`${width[0].c4}`}} onClick={() => applySorting('sku', !sorting.ascending)}>Sku {sorting.ascending ? shortingIcon && sorting.field ==="sku" && <span><BiUpArrowAlt /></span> : shortingIcon && sorting.field ==="sku" && <span><BiDownArrowAlt /></span>}</th>
						<th style={{width:`${width[0].c5}`}} onClick={() => applySorting('type', !sorting.ascending)}>Product Type {sorting.ascending ? shortingIcon && sorting.field ==="type" && <span><BiUpArrowAlt /></span> : shortingIcon && sorting.field ==="type" &&<span><BiDownArrowAlt /></span>}</th>
						<th style={{width:`${width[0].c6}`}} onClick={() => applySorting('packSize', !sorting.ascending)}>Pack Size {sorting.ascending ? shortingIcon && sorting.field ==="packSize" && <span><BiUpArrowAlt /></span> : shortingIcon && sorting.field ==="packSize" && <span><BiDownArrowAlt /></span>}</th>
						<th style={{width:`${width[0].c7}`}} onClick={() => applySorting('gst', !sorting.ascending)}>GST {sorting.ascending ? shortingIcon && sorting.field ==="gst" && <span><BiUpArrowAlt /></span> : shortingIcon && sorting.field ==="gst" && <span><BiDownArrowAlt /></span>}</th>
						<th style={{width:`${width[0].c8}`}} onClick={() => applySorting('hsn', !sorting.ascending)}>HSN Code {sorting.ascending ? shortingIcon && sorting.field ==="hsn" && <span><BiUpArrowAlt /></span> : shortingIcon && sorting.field ==="hsn" && <span><BiDownArrowAlt /></span>}</th>
						<th style={{width:`${width[0].c9}`}} onClick={() => applySorting('category', !sorting.ascending)}>Category {sorting.ascending ? shortingIcon && sorting.field ==="category" && <span><BiUpArrowAlt /></span> : shortingIcon && sorting.field ==="category" && <span><BiDownArrowAlt /></span>}</th>
						<th style={{width:`${width[0].c10}`}} onClick={() => applySorting('active', !sorting.ascending)}>Active {sorting.ascending ? shortingIcon && sorting.field ==="active" && <span><BiUpArrowAlt /></span> : shortingIcon && sorting.field ==="active" &&<span><BiDownArrowAlt /></span>}</th>
						<th style={{width:`${width[0].c11}`}}>Offers</th>
					</tr>
					<tr className='product-searchbar'>
						<th ></th>
						<th ><input type='text' style={{width:"100%"}} onChange={handleSearch} /></th>
						<th ><input type='text' style={{width:"100%"}} onChange={handleSearch} /></th>
						<th ><input type='text' style={{width:"100%"}} onChange={handleSearch} /></th>
						<th ><input type='text' style={{width:"100%"}} onChange={handleSearch} /></th>
						<th ><input type='text' style={{width:"100%"}} onChange={handleSearch} /></th>
						<th ><input type='text' style={{width:"100%"}} onChange={handleSearch} /></th>
						<th ><input type='text' style={{width:"100%"}} onChange={handleSearch} /></th>
						<th ><input type='text' style={{width:"100%"}} onChange={handleSearch} /></th>
						<th ><input type='text' style={{width:"100%"}} onChange={handleSearch} /></th>
						<td>Offers</td>
					</tr>
					{addData.slice(page * pageSize, page * pageSize + pageSize).map((val, key) => {
						;
						return (
							<tr key={key}>
								<td>
									<div className='table-action-btn'>
										<IconButton onClick={() => handleEdit(val, key)} color='success'><BiEditAlt /></IconButton>
										<IconButton size='small' color='error' onClick={() => handleDelete(key)} ><FaTrash /></IconButton>
										<IconButton ><BsFillTagFill /></IconButton>

									</div>
								</td>
								<td>{val.product}</td>
								<td>{val.cName}</td>
								<td>{val.sku}</td>
								<td>{val.type}</td>
								<td>{val.packSize}</td>
								<td>{val.gst}</td>
								<td>{val.hsn}</td>
								<td>{val.category}</td>
								<td>{val.active}</td>
								<td>{val.sku}</td>
							</tr>
						)
					})}
					<tr >
						<td  colSpan={6}> <TablePagination
							rowsPerPageOptions={[5, 10, 25]}
							component="div"
							count={addData.length}
							rowsPerPage={pageSize}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage} /></td>
					</tr>
				</table>
			</div>


			{openAdd && <Box className="add-dialog-box" >
				<Box className="add-dialog-inner" sx={{ py: 2, px: 3, }}>
					<Box className="add-heading">
						<h3>Add Product</h3>
						<IconButton color='primary' onClick={handleAddClose}><AiOutlineCloseCircle /></IconButton>
					</Box>
					<Box className='add-dialog-inputs'>
						<TextField
							label="Company Name"
							size="small"
							name='cName'
							value={addInput.cName}
							onChange={handleChangeAddInput}
							className='add-inputs'
							shrink
						/>
						<TextField
							label="Composition Name"
							size="small"
							name='composition'
							value={addInput.composition}
							onChange={handleChangeAddInput}
							className='add-inputs'
							shrink
						/>
					</Box>

					<Box className='add-dialog-inputs'>
						<TextField
							label="Product Name"
							size="small"
							name='product'
							value={addInput.product}
							onChange={handleChangeAddInput}
							className='add-inputs'
							shrink
						/>

						<FormControl className='add-inputs' size="small">
							<InputLabel id="demo-select-small-label">Select Product Type</InputLabel>
							<Select
								labelId="demo-select-small-label"
								id="demo-select-small"
								name='type'
								value={addInput.type}
								label="Select Product Type"
								onChange={handleChangeAddInput}
							>
								<MenuItem value="General">General</MenuItem>
								<MenuItem value="Lifestyle">Lifestyle</MenuItem>
								<MenuItem value='Travel'>Travel</MenuItem>
								<MenuItem value='Design'>Design</MenuItem>
								<MenuItem value='Creative'>Creative</MenuItem>
								<MenuItem value='Educaion'>Educaion</MenuItem>
							</Select>
						</FormControl>
					</Box>

					<Box className='add-dialog-inputs'>
						<TextField
							label="Pack Size"
							size="small"
							name='packSize'
							value={addInput.packSize}
							onChange={handleChangeAddInput}
							className='add-inputs'
							shrink
						/>

						<TextField
							label="GST"
							size="small"
							name='gst'
							value={addInput.gst}
							onChange={handleChangeAddInput}
							className='add-inputs'
							shrink
						/>
					</Box>

					<Box className='add-dialog-inputs'>
						<TextField
							label="HSN Code"
							size="small"
							name='hsn'
							value={addInput.hsn}
							onChange={handleChangeAddInput}
							className='add-inputs'
							shrink
						/>

						<FormControl className='add-inputs' size="small">
							<InputLabel id="demo-select-small-label">Select Product Category</InputLabel>
							<Select
								labelId="demo-select-small-label"
								id="demo-select-small"
								name='category'
								value={addInput.category}
								label="Select Product Category"
								onChange={handleChangeAddInput}
							>
								<MenuItem value="General">General</MenuItem>
								<MenuItem value="Lifestyle">Lifestyle</MenuItem>
								<MenuItem value='Travel'>Travel</MenuItem>
								<MenuItem value='Design'>Design</MenuItem>
								<MenuItem value='Creative'>Creative</MenuItem>
								<MenuItem value='Educaion'>Educaion</MenuItem>
							</Select>
						</FormControl>
					</Box>

					<Box className='add-dialog-inputs'>
						<FormControlLabel
							value="Is-Active"
							name='active'
							control={<Switch color="primary" />}
							label="Is Active"
							onChange={handleChangeAddInput}
							labelPlacement="end"
						/>

						<TextField
							label="SKU"
							size="small"
							name='sku'
							value={addInput.sku}
							onChange={handleChangeAddInput}
							className='add-inputs'
							shrink
						/>
					</Box>
					<Box>
						{editData ? <Button className='add-save-btn' variant="contained" onClick={handleAddEdit}>Update</Button> : <Button className='add-save-btn' variant="contained" onClick={handleAddSave}>Save</Button>}
					</Box>
				</Box>
			</Box>}
		</>
	)
}
































// import React, { createRef, useState } from 'react'
// import { Paper, Typography, Grid, Box, Avatar, Button } from '@mui/material';
// import { useTheme } from '@mui/styles';
// import { BsBoxArrowInUp, BsFillTrashFill } from 'react-icons/bs'

// const Profile = () => {
// 	const themes = useTheme();
// 	const theme = themes.palette;

// 	const [image, _setImage] = useState(null);
// 	const inputFileRef = createRef(null);


// 	const setImage = (newImage) => {

// 		_setImage(newImage);
// 	};

// 	const handleOnChange = (event) => {
// 		const newImage = event.target?.files?.[0];

// 		if (newImage) {
// 			setImage(URL.createObjectURL(newImage));
// 		}
// 	};


// 	return (
// 		<Paper variant='none' sx={{ backgroundColor: theme.white.blight }} >
// 			<Box sx={{ bgcolor: theme.white.main, borderRadius: "10px", mb: 2 }}>
// 				<Grid item container sx={{ p: 2, justifyContent: "space-between" }} columns={{ xs: 8, sm: 4, md: 8 }} >
// 					<Grid item sx={{ color: theme.secondary.main }}>
// 						<Typography variant="h6" gutterBottom sx={{ mt: 1, pl: 1, color: theme.text.main }} >
// 							Profile
// 						</Typography>
// 					</Grid>
// 				</Grid>
// 			</Box>
// 			<Box sx={{ bgcolor: theme.white.main, borderRadius: "10px", mt: 2, py: 2 }}>
// 				<Grid item container sx={{ justifyContent: "center" }}>
// 					<Grid item xs={10} sx={{ textAlign: "-webkit-center" }}>
// 					<Box>
// 						<Avatar
// 							alt="Remy Sharp"
// 							src={image || "/static/img/avatars/default-profile.svg"}
// 							sx={{ width: 150, height: 150 }}
// 						/>
// 						</Box>
// 					</Grid>
// 					<Grid item xs={12}>
// 						<input ref={inputFileRef} accept="image/*" hidden id="avatar-image-upload" type="file" onChange={handleOnChange} />
// 						<label htmlFor="avatar-image-upload" style={{ marginTop: "10px" }} >
// 							<Button variant="contained" color="primary" component="span" mb={2}>
// 								Upload Profile
// 							</Button>
// 						</label>
// 					</Grid>
// 				</Grid>
// 			</Box>
// 		</Paper>
// 	)
// }

// export default Profile;