import { makeStyles } from "@mui/styles";

export default makeStyles(theme => {
    return ({
        sidebarContainer: {
            '& .outlet-sidebar.open': {
                minWidth: "250px",
            },

            '& .outlet-sidebar.close .sidebar-container .sidebar-header': {
                padding: "8px 5px",
                marginBottom: "10px",
                flexDirection: "column"
            },

            '& .sidebar-header': {
                display: "flex"
            }
        }
    });
});