import { Box, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material'
import React, { useState } from 'react'
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';

export default function ProductAdd() {
  const [input, setInput] = useState({
    name: "",
    description: "",
    image: "",
    price: '',
    category: '',
    showImg: ''
  });
  const [inputFields, setInputFields] = useState([{ heading: "", content: "" }]);
  const [imageUrl, setImageUrl] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };

  const handleContent = (index, e) => {
    const { name, value } = e.target;
    const newInputField = [...inputFields];
    newInputField[index][name] = value;
    setInputFields(newInputField);
  };

  const handleRemoveFields = (index) => {
    if (inputFields.length < 2) {

    } else {
      const newInputFiled = [...inputFields];
      newInputFiled.splice(index, 1);
      setInputFields(newInputFiled);
    };
  };

  const handleAddFields = () => {
    const newFiledAdd = [...inputFields];
    newFiledAdd.push({ heading: "", content: '' });
    setInputFields(newFiledAdd);
  };

  const handleImage = (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      setImageUrl(reader.result);
      setInput({ ...input, image: reader.result });
    };

    reader.onerror = (err) => {
      console.log(err);
    };
  };

  return (
    <Paper>
      <Box>
        <Grid item container columns={{ xs: 4, sm: 8, md: 10 }} sx={{ rowGap: "25px", borderRadius: "10px", backgroundColor: "white.main", p: 3, justifyContent: "space-between" }}  >
          <Grid item xs={12} >
            <Box sx={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
            <TextField
              label="Product Name"
              id="outlined-size-small"
              size="small"
              name='name'
              value={input.name}
              onChange={handleChange}
              sx={{ width: "60%" }}
              shrink
            />
            <FormControl sx={{  width:"35%" }} size="small">
              <InputLabel id="demo-select-small-label">Category</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                name='category'
                value={input.category}
               label='Category'
                onChange={handleChange}
              >
                <MenuItem value="App">App</MenuItem>
                <MenuItem value="Product">Product</MenuItem>
                <MenuItem value="Branding">Branding</MenuItem>
                <MenuItem value="Books">Books</MenuItem>
              </Select>
            </FormControl>
            </Box>
            
          </Grid>
          <Grid item xs={12} >
            {inputFields?.map((inputField, index) => (
              <Box sx={{ display: 'flex', justifyContent: "space-evenly", alignItems: "center", margin: "15px 0px", boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px", borderRadius: "10px" }} key={index}  >
                <Box sx={{ width: '10%', textAlign: "center" }}><IconButton type="button" color='primary' onClick={() => handleRemoveFields(index)} ><AiOutlineMinusCircle /></IconButton></Box>
                <Box sx={{ width: '70%', textAlign: "center", }}>
                  <TextField
                    label="Heading"
                    type="text"
                    size='small'
                    name='heading'
                    value={inputField.heading}
                    onChange={(event) => handleContent(index, event)}
                    sx={{ width: '80%', margin: "15px 0px" }}
                    shrink
                  />
                  <TextField
                    id="outlined-multiline-static"
                    label="Content"
                    multiline
                    rows={3}
                    name='content'
                    value={inputField.content}
                    onChange={(event) => handleContent(index, event)}
                    sx={{ width: '80%', margin: "10px 0px" }}
                    shrink
                  />
                </Box>
                <Box sx={{ width: '10%', textAlign: "center" }}><IconButton type="button" color='primary' onClick={handleAddFields} ><AiOutlinePlusCircle /></IconButton></Box>
              </Box>
            ))}
          </Grid>
          <Grid item xs={5} >
            <TextField
              accept="image/*"
              type='file'
              label="Image"
              multiple
              onChange={handleImage}
              sx={{ width: "100%" }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          {imageUrl !== null && <Grid item xs={4}  >
            <img src={imageUrl} alt="" style={{ width: "100%", height: "auto", }} />
          </Grid>}
          <Grid>
           
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}
