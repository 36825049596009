import React, { useState } from 'react';
import { Box, IconButton, InputBase, Tooltip, Avatar, Menu, MenuItem, Typography, Badge, styled, Stack } from '@mui/material';
import { BsSearch, BsBell, BsMailbox,BsPersonCircle,BsArrowBarLeft,BsGearFill } from 'react-icons/bs';
import { useTheme } from '@mui/styles';
import '../../assets/css/header.css';
import { Link } from 'react-router-dom';


// import { HiOutlineMenuAlt4 } from 'react-icons/hi';


const Header = ({ sidebarToggle: _s }) => {
	const themes = useTheme();
	const theme = themes.palette;
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};


	return (
		<Box sx={{ height: '81px', width: '100%', px: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderBottom: '1px solid', borderBottomColor: 'white.blight', bgcolor: 'white.main' }}>
			{/* <Box><IconButton onClick={e => sidebarToggle()}><HiOutlineMenuAlt4 /></IconButton></Box> */}
			<Box sx={{ border: "1px solid", borderColor: theme.white.light, borderRadius: "10px" }} >
				<InputBase
					sx={{ ml: 1, flex: 1, p: 1 }}
					placeholder="Search"
				/>
				<IconButton type="button" sx={{ p: '10px' }} aria-label="search">
					<BsSearch />
				</IconButton>
			</Box>

			<Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
				<Box>
					<Stack direction="row" spacing={2}>
						<IconButton color="primary" className='blink'><Badge color='error' variant="dot"><BsBell /></Badge></IconButton>
						<IconButton color="primary" className='blink'><Badge color='error' variant="dot"><BsMailbox /></Badge></IconButton>
					</Stack>
				</Box>
				<Box sx={{ flexGrow: 0 }}>
					<Tooltip title="Open Profile">
						<IconButton
					 		sx={{ p: 0 }}
							aria-controls={open ? 'basic-menu' : undefined}
							aria-haspopup="true"
							aria-expanded={open ? 'true' : undefined}
							onClick={handleClick}>
							<Avatar alt="" src="/static/images/avatar/2.jpg" />
						</IconButton>
					</Tooltip>
					<Menu
						id="basic-menu"
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						MenuListProps={{
							'aria-labelledby': 'basic-button',
						}}
					>
						<MenuItem onClick={handleClose} sx={{px:3}}><Link to={`profile`} style={{textDecoration:"none",color:theme.text.main}}><IconButton><BsPersonCircle/></IconButton>Profile</Link></MenuItem>
						<MenuItem onClick={handleClose} sx={{px:3}}><Link to={`setting`} style={{textDecoration:"none",color:theme.text.main}}><IconButton><BsGearFill/></IconButton>Settings</Link></MenuItem>
						<MenuItem onClick={handleClose} sx={{px:3}}><IconButton><BsArrowBarLeft/></IconButton>Logout</MenuItem>
					</Menu>
				</Box>
			</Stack >
		</Box >
	)
}

export default Header;