import { Box, Button, IconButton, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { MdOutlineAddAPhoto } from 'react-icons/md'
import { deleteInqury, singalInqury } from '../../../api';
import { BiTrashAlt } from 'react-icons/bi';
import { toast } from 'react-hot-toast';

export default function InquryView({ setView, inquryId, setAllInqury, allinqury }) {
    const [inqury, setInqury] = useState([]);

    const getSingalInqury = async () => {
        try {
            const { data } = await singalInqury(inquryId);
            setInqury(data.data);
        } catch (err) {
            console.log(err);
        };
    };


    const hendleDelete = async () => {
        const loading = toast.loading("in progres...")
        try {
            const { data } = await deleteInqury(inquryId);
            if (data.status === "success") {
                const deletedBlog = allinqury.filter((element) => {
                    return inquryId !== element._id
                });
                setAllInqury(deletedBlog);
                setView(false);
                toast.dismiss(loading);
                toast.success("Deleted successfully");
            } else {
                toast.dismiss(loading);
                toast.error("Internal Error");
            };
        } catch (err) {
            console.log(err);
        };
    };


    useEffect(() => {
        getSingalInqury();
    }, []);

    return (
        <Box>
            <Box classname="dialog-box" sx={{ position: 'fixed', top: '0', left: '0', right: '0', bottom: '0', backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Box classname="dialog-inner" sx={{ position: 'relative', borderRadius: "10px", bgcolor: 'white.main', maxWidth: '60%', width: '60%', py: 2, px: 3, }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant='h6' sx={{  fontWeight: "600" }}>Inqury View</Typography>
                        <IconButton color='primary' sx={{}} onClick={() => setView(false)}><AiOutlineCloseCircle /></IconButton>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                        <Box sx={{ display: "flex", alignItems: "center",width:"50%" }}>
                            <Typography variant='p' sx={{ width: "20%", fontWeight: "600" }}>Name</Typography>
                            <TextField
                                size="small"
                                disabled
                                value={inqury.name}
                                variant="standard"
                                sx={{ width: "75%", }}
                            />
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center",width:"50%" }}>
                            <Typography variant='p' sx={{ width: "20%", fontWeight: "600" }}>Email</Typography>
                            <TextField
                                size="small"
                                disabled
                                value={inqury.email}
                                variant="standard"
                                sx={{ width: "80%" }}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                        <Typography variant='p' sx={{ width: "10%", fontWeight: "600" }}>Subject</Typography>
                        <TextField
                            size="small"
                            disabled
                            value={inqury.subject}
                            variant="standard"
                            sx={{ width: "90%" }}
                        />
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
                        <Typography variant='p' sx={{ width: "10%", fontWeight: "600" }}>Message</Typography>
                        <TextField
                            size="small"
                            disabled
                            multiline
                            rows={3}
                            value={inqury.message}
                            variant="outlined"
                            sx={{ width: "90%" }}
                        />
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", mt: 2, justifyContent: "center" }}>
                        <Button color='error' variant="outlined" onClick={hendleDelete}><BiTrashAlt /> &nbsp; Deleted</Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
