import { Box, Button, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { EditBlog, addBlog } from '../../../api';
import { toast } from 'react-hot-toast';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';

export default function AddBlog({ setOpen, allBlog, setAllBlog, editedData, edit, setEdit }) {

    const [inputFields, setInputFields] = useState([{ heading: '', content: "" }]);
    const [input, setInput] = useState({
        title: "",
        publiser: "",
        aurther: "",
        category: "",
        types:"",
        description: "",
        image: "",
        content: '',
        contentimg:""
    });
    const [imageUrl, setImageUrl] = useState("");
    const [contentImgUrl, setContentImgUrl] = useState("");
    const [contentIndex, setContentIndex] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInput({ ...input, [name]: value });
    };

    const handleImage = (e) => {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);

        reader.onload = () => {
            setImageUrl(reader.result);
            setInput({ ...input, image: reader.result });
        };
        reader.onerror = (err) => {
            console.log(err);
        };
    };

    const handleCantentImage = (e) => {
        const reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);

        reader.onload = () => {
            setContentImgUrl(reader.result);
            setInput({ ...input, contentimg: reader.result });
        };
        reader.onerror = (err) => {
            console.log(err);
        };
    };

    const handleSubmit = async () => {
        const loading = toast.loading("In progress...");
        const blogData = { ...input, content: inputFields };
        console.log(blogData);
        try {
            const { data } = await addBlog(blogData);

            if (data.status === "success") {
                toast.dismiss(loading);
                toast.success("Upload successfully");
                setAllBlog([...allBlog, data.data]);
                setOpen(false);
                setImageUrl(null);
            };
        } catch (err) {
            console.log(err);
            toast.dismiss(loading);
            toast.error(err.response.data);
        };
    };

    const handleUpadate = async () => {
        const loading = toast.loading("In progress...");
        const updateData = { ...input, content: inputFields };
        try {
            const { data } = await EditBlog(editedData._id, updateData);
            if (data.status !== "success") throw new Error("Intenal error");
            const updatedData = allBlog?.map((ele) => {
                if (ele._id === editedData._id) {
                    const backendData = data.data;
                    return { ...ele, title: backendData.title, aurther: backendData.aurther, publiser: backendData.publiser, image: backendData.image, description: backendData.description, content: backendData.content, }
                }
                return ele;
            });

            toast.dismiss(loading);
            toast.success("Updated");
            setAllBlog(updatedData);
            setEdit(false);
            setOpen(false);

        } catch (err) {
            toast.dismiss(loading);
            toast.error(err.response.data);
        };
    };


    const handleAddFields = () => {
        const newInputFields = [...inputFields];
        newInputFields.push({ heading: '', content: "" });
        setInputFields(newInputFields);

    };

    const handleContent = (index, event) => {
        const { value, name } = event.target;
        const newInputFields = [...inputFields];
        newInputFields[index][name] = value;
        setInputFields(newInputFields);
        setContentIndex(index)
    };

    const handleRemoveFields = (index) => {
        if (inputFields.length < 2) {

        } else {
            const newInputFields = [...inputFields];
            newInputFields.splice(index, 1);
            setInputFields(newInputFields);
        };
    };

    // const handleAddList = () => {
    //     setList([...list, listInput]);


    //     // console.log(contentIndex);
    //     // const newList = [...inputFields,];
    //     // console.log(listInput);

    //     // newList[contentIndex] = { ...newList[contentIndex], content: [...listInput,listInput] }
    //     // setInputFields(newList);
    //     // setContentIndex("");
    // };
    console.log(inputFields);

    useEffect(() => {
        if (edit === true) {
            setInput(editedData);
            setInputFields(editedData.content);
            setImageUrl(editedData.image);
        };
    }, []);

    return (
        <Paper>
            <Box>
                <Grid item container columns={{ xs: 4, sm: 8, md: 10 }} sx={{ rowGap: "25px", borderRadius: "10px", backgroundColor: "white.main", p: 3, justifyContent: "space-between" }}  >
                    <Grid item xs={12} >
                        <TextField
                            label="Title"
                            id="outlined-size-small"
                            size="small"
                            name='title'
                            value={input.title}
                            onChange={handleChange}
                            sx={{ width: "100%" }}
                            shrink
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Aurther"
                            id="outlined-size-small"
                            size="small"
                            name='aurther'
                            value={input.aurther}
                            onChange={handleChange}
                            sx={{ width: "100%" }}
                            shrink
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <TextField
                            label="Publiser"
                            id="outlined-size-small"
                            size="small"
                            name='publiser'
                            value={input.publiser}
                            onChange={handleChange}
                            sx={{ width: "100%" }}
                            shrink
                        />
                    </Grid>

                    <Grid item xs={12} sx={{display:"flex",alignItems:'center'}} >
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="demo-select-small-label">Category</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            name='category'
                            value={input.category}
                            label="Category"
                            onChange={handleChange}
                        >
                            <MenuItem value="General">General</MenuItem>
                            <MenuItem value="Lifestyle">Lifestyle</MenuItem>
                            <MenuItem value='Travel'>Travel</MenuItem>
                            <MenuItem value='Design'>Design</MenuItem>
                            <MenuItem value='Creative'>Creative</MenuItem>
                            <MenuItem value='Educaion'>Educaion</MenuItem>
                        </Select>
                    </FormControl>
                    
                        <TextField
                            label="Types"
                            id="outlined-size-small"
                            size="small"
                            name='types'
                            value={input.types}
                            onChange={handleChange}
                            sx={{ width: "100%" }}
                            shrink
                        />
                    </Grid>
                    {/* <Grid item xs={12} >
                        <TextField
                            label="Date"
                            type='date'
                            id="outlined-size-small"
                            size="small"
                            name='date'
                            value={input.date}
                            onChange={handleChange}
                            sx={{ width: "100%" }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid> */}
                    <Grid item xs={12} >
                        <TextField
                            id="outlined-multiline-static"
                            label="Description"
                            multiline
                            rows={3}
                            name='description'
                            value={input.description}
                            onChange={handleChange}
                            sx={{ width: "100%" }}
                            shrink
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <Box>
                            {inputFields?.map((inputField, index) => (
                                <Box sx={{ display: 'flex', justifyContent: "space-evenly", alignItems: "center", margin: "7px 0px" }} key={index}  >
                                    <Box sx={{ width: '10%', textAlign: "center" }}><IconButton type="button" color='primary' onClick={() => handleRemoveFields(index)} ><AiOutlineMinusCircle /></IconButton></Box>
                                    <Box sx={{ border: "1px solid gray", width: '70%', textAlign: "center", borderRadius: "10px" }}>
                                        <TextField
                                            label="Heading"
                                            type="text"
                                            size='small'
                                            name='heading'
                                            value={inputField.heading}
                                            onChange={(event) => handleContent(index, event)}
                                            sx={{ width: '80%', margin: "10px 0px" }}
                                            shrink
                                        />
                                        <TextField
                                            id="outlined-multiline-static"
                                            label="Content"
                                            multiline
                                            rows={3}
                                            name='content'
                                            value={inputField.content}
                                            onChange={(event) => handleContent(index, event)}
                                            sx={{ width: '80%', margin: "10px 0px" }}
                                            shrink
                                        />
                                    </Box>
                                    <Box sx={{ width: '10%', textAlign: "center" }}><IconButton type="button" color='primary' onClick={handleAddFields} ><AiOutlinePlusCircle /></IconButton></Box>
                                </Box>
                            ))}
                        </Box>
                    </Grid>
                    <Grid item xs={5} >
                        <TextField
                            accept="image/*"
                            type='file'
                            label="Image"
                            multiple
                            onChange={handleImage}
                            sx={{ width: "100%" }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    {imageUrl !== null && <Grid item xs={4}  >
                        <img src={imageUrl} alt="" style={{ width: "100%", height: "auto", }} />
                    </Grid>}
                    <Grid item xs={5} >
                        <TextField
                            accept="image/*"
                            type='file'
                            label="Content Image"
                            multiple
                            onChange={handleCantentImage}
                            sx={{ width: "100%" }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    {contentImgUrl !== null && <Grid item xs={4}  >
                        <img src={contentImgUrl} alt="" style={{ width: "100%", height: "auto", }} />
                    </Grid>}
                    <Grid item xs={12} >

                        {edit ? <Button type='submit' onClick={handleUpadate} variant="contained" sx={{ color: "white.main" }}>Update</Button>
                            : <Button type='submit' onClick={handleSubmit} variant="contained" sx={{ color: "white.main" }}>Submit</Button>}
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    )
}
